import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { PatientListFilter } from '.';
import {
  FetchPatientsObj,
  useAuthorization,
  useFetchEhrPatients,
  useFetchPatients,
  usePatientFilter,
} from '../../../hooks';
import { getLocalDateTimeStringFromISO, PAGE_URL } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { AppPagination } from '../../../components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

interface Props {
  projectId?: number;
  projectName?: string;
  isEhrProject: boolean;
}

interface PatientListDataType {
  key: React.Key;
  patientName: string;
  patientId: number;
  uploadedDate: string;
  projectName: string;
  status: string;
  statusColor: string;
  reviewedDate: string;
  mrn: string;
  projectId: number;
}

const getColumns = (): ColumnsType<PatientListDataType> => [
  {
    title: "File Name",
    dataIndex: "FileName",
    sorter: true,
  },
  {
    title: "Subject Name",
    dataIndex: "patientName",
  },
  {
    title: 'Subject ID',
    dataIndex: 'mrn',
  },
  {
    title: 'Project Name',
    dataIndex: 'projectName',
    sorter: true,
  },
  {
    title: 'Uploaded Date',
    dataIndex: 'uploadedDate',
    sorter: true,
  },
  {
    title: 'Reviewed Date',
    dataIndex: 'reviewedDate',
    sorter: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (value, record) => (
      <div style={{ color: record.statusColor }}>{value}</div>
    ),
  },
];

export const AssociatedPatientsList = ({ projectId, projectName, isEhrProject }: Props) => {
  const navigate = useNavigate();
  const { setIsAuthorized } = useAuthorization();
  const {
    getPatientFilterObject,
    paginationObj,
    patientStatusFilter,
    searchFilter,
    sortFilter,
  } = usePatientFilter({
    onPatientsList: true,
    setIsAuthorized,
  });
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;
  const { onPatientStatusChange, patientStatusLoading, patientStatusOptions } =
    patientStatusFilter;
  const { onSearchChange, searchKeyword } = searchFilter;
  const { setSort } = sortFilter;
  const { fetchPatients, patientsLoading, patients, totalPatients } =
    useFetchPatients({ setIsAuthorized });
  const { ehrPatients, fetchEhrPatients, loadingEhrPatients } =
    useFetchEhrPatients();
  const [patientFetchAllowedFor, setPatientFetchAllowedFor] = useState<
    'EhrPatients' | 'Patients' | null
  >(null);
  const { projectEhrEnvMap } = useSelector(
    (state: RootState) => state.connectEhr,
  );

  useEffect(() => {
    if (projectId && isEhrProject) {
      setPatientFetchAllowedFor('EhrPatients');
      if(projectId in projectEhrEnvMap)
        fetchEhrPatients(projectEhrEnvMap[projectId]);
    } else {
      setPatientFetchAllowedFor('Patients');
    }
  }, [fetchEhrPatients, projectEhrEnvMap, projectId]);

  const ehrPatientsData: PatientListDataType[] = ehrPatients.map(
    (patient, index) => ({
      key: `patient-list-${index}`,
      mrn: patient.id,
      patientId: Number(patient.id),
      patientName: patient.name[0].text || '',
      projectId: projectId ? projectId : -1,
      projectName: projectName || '-',
      reviewedDate: '-',
      status: 'Not reviewed',
      statusColor: '#76DEFF',
      uploadedDate: '-',
      FileName: `${patient.id}.pdf`,
    }),
  );

  const patientsData: PatientListDataType[] = patients.map(
    (patient, index) => ({
      key: `patient-list-${index}`,
      mrn: patient.Mrn,
      patientId: patient.Id,
      patientName: patient.Name,
      projectName: patient.projectName,
      projectId: patient.projectId,
      reviewedDate: getLocalDateTimeStringFromISO(
        patient.projectStatusUpdatedAt,
      ),
      status: patient.projectStatusName,
      statusColor: patient.projectStatusColorCode,
      uploadedDate: getLocalDateTimeStringFromISO(patient.CreatedAt),
      FileName: patient.FileName
        ? patient.FileName.split('/')[patient.FileName.split('/').length - 1]
        : patient.Name,
    }),
  );

  const onTableChange: TableProps<PatientListDataType>['onChange'] = (
    pagination,
    filters,
    sorter,
  ) => {
    setSort(sorter);
  };

  const handleRowClick = (item: PatientListDataType, isEhrPatient = false) => {
    let envId = ""
    if(isEhrPatient && projectId && projectId in projectEhrEnvMap) {
      envId = projectEhrEnvMap[projectId];
    }
    navigate(
      `${PAGE_URL.PATIENT_PROFILE}/${item.patientId}?projectId=${item.projectId}&ehr=${isEhrPatient}&envId=${envId}`,
    );
  };

  useEffect(() => {
    const getPatients = async () => {
      let obj: FetchPatientsObj = { assigned: true };
      if (projectId) obj = { ...obj, projectId };
      obj = getPatientFilterObject(obj);
      await fetchPatients(obj);
    };
    if (patientFetchAllowedFor && patientFetchAllowedFor === 'Patients')
      getPatients();
  }, [
    fetchPatients,
    getPatientFilterObject,
    patientFetchAllowedFor,
    projectId,
  ]);

  return (
    <>
      {!patientFetchAllowedFor && (
        <div className="patients-tab">
          <Table
            className="basic-detail-content tb-scroll status-tag"
            rowClassName="cursor-pointer"
            columns={getColumns()}
            loading={true}
            pagination={false}
          />
        </div>
      )}
      {patientFetchAllowedFor === 'Patients' && (
        <div className="patients-tab">
          <PatientListFilter
            onPatientsList
            {...{
              onSearchChange,
              searchKeyword,
              patientStatusLoading,
              patientStatusOptions,
              onPatientStatusChange,
            }}
          />
          <AppPagination
            {...{
              showSizeChanger: true,
              current: page,
              total: totalPatients,
              pageSize,
              pageSizeOptions,
              onChange: onPaginationChange,
            }}
          />
          <Table
            className="basic-detail-content tb-scroll status-tag"
            rowClassName="cursor-pointer"
            columns={getColumns()}
            dataSource={patientsData}
            onChange={onTableChange}
            loading={patientsLoading}
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record),
              };
            }}
            pagination={false}
          />
        </div>
      )}
      {patientFetchAllowedFor === 'EhrPatients' && (
        <div className="patients-tab">
          <Table
            className="basic-detail-content tb-scroll status-tag"
            rowClassName="cursor-pointer"
            columns={getColumns()}
            dataSource={ehrPatientsData}
            loading={loadingEhrPatients}
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record, true),
              };
            }}
            pagination={false}
          />
        </div>
      )}
    </>
  );
};
