import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IProjectEhrEnvironmentMap {
  [projectId: number]: string;
}

interface ConnectEhrSlice {
  projectEhrEnvMap: IProjectEhrEnvironmentMap;
}
export const connectEhrInitialState: ConnectEhrSlice = {
  projectEhrEnvMap: {} as IProjectEhrEnvironmentMap,
};

export const connectEhrSlice = createSlice({
  name: 'connectEhr',
  initialState: connectEhrInitialState,
  reducers: {
    setProjectEhrEnvironment: (
      state: ConnectEhrSlice,
      { payload }: PayloadAction<{ projectId: number; environmentId: string }>,
    ) => {
      const { environmentId, projectId } = payload;
      state.projectEhrEnvMap[projectId] = environmentId;
    },
    removeProjectEhrEnvironment: (
      state: ConnectEhrSlice,
      { payload }: PayloadAction<number>,
    ) => {
      if (payload in state.projectEhrEnvMap) {
        delete state.projectEhrEnvMap[payload];
      }
    },
  },
});

export const { setProjectEhrEnvironment, removeProjectEhrEnvironment } =
  connectEhrSlice.actions;

export default connectEhrSlice.reducer;
