import { useCallback, useState } from 'react';
import { useAuthorization } from './useAuthorization';
import { STATUS_CODE, openNotificationWithIcon } from '../utils';
import { ehrService } from '../api';

export const useFetchEhrPatients = () => {
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState<IEhrPatient[]>([]);

  const { setIsAuthorized } = useAuthorization();

  const fetchEhrPatients = useCallback(
    async (envId: string) => {
      setLoading(true);
      try {
        const { data } = await ehrService.getPatients(envId);
        setPatients([...data]);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon('', err.response.data.message, 'error');
        }
      } finally {
        setLoading(false);
      }
    },
    [setIsAuthorized],
  );

  return {
    loadingEhrPatients: loading,
    fetchEhrPatients,
    ehrPatients: patients,
  };
};
