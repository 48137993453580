import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal, Skeleton, Space, Table } from 'antd';
import { ConnectEhrProps } from '.';
import { CloseOutlined } from '@ant-design/icons';
import { useConnectEhr } from '../../../hooks';
import { NotFitIcon } from '../../../assets/icons';
import { ColumnsType } from 'antd/lib/table';
import { openNotificationWithIcon } from '../../../utils';
import "./connect-ehr.scss";

interface IPlatformsDataType {
  key: string;
  platformName: string;
  platformType: string;
}

const getColumns = (): ColumnsType<IPlatformsDataType> => [
  {
    title: 'Platform Name',
    dataIndex: 'platformName',
  },
  {
    title: 'Platform Type',
    dataIndex: 'platformType',
  },
];

const ConnectEHR = ({
  visibility,
  handleClose,
  projectId,
  environmentId,
  connectProjectToEhrEnv,
}: ConnectEhrProps) => {
  const {
    environments,
    fetchEnvironments,
    fetchPlatforms,
    loadingEnvironments,
    loadingPlatforms,
    platforms,
  } = useConnectEhr();
  const [expandedRowKey, setExpandedRowKey] = useState<string | null>(null);
  const [platformId, setPlatformId] = useState<string | null>(null);

  useEffect(() => {
    if (platformId) fetchEnvironments(platformId);
  }, [fetchEnvironments, platformId]);

  useEffect(() => {
    fetchPlatforms();
  }, [fetchPlatforms]);

  const onExpand = (expanded: boolean, record: IPlatformsDataType) => {
    if (expanded) {
      setExpandedRowKey(record.key);
      setPlatformId(record.key);
    } else {
      setExpandedRowKey(null);
    }
  };

  const handleEhrConnection = useCallback(
    (env: IEnvironment) => {
      connectProjectToEhrEnv(projectId, env._id);
      openNotificationWithIcon(
        '',
        'Environment Connected Successfully',
        'success',
      );
      handleClose();
    },
    [connectProjectToEhrEnv, handleClose, projectId],
  );

  const dataSource: IPlatformsDataType[] = useMemo(
    () =>
      platforms.map((platform) => ({
        key: platform._id,
        platformName: platform.name,
        platformType: platform.associatedPlatform,
      })),
    [platforms],
  );

  const expandedRowRender = () => {
    return (
      <div>
        {environments.length === 0 && !loadingEnvironments && (
          <div className="no-data-result aic jcc flex">
            <p>No Environments Found</p>
          </div>
        )}
        {loadingEnvironments ? (
          <Space className="w-full gp-10 mt-20" size="large">
            <Skeleton.Button className="sandbox-button-skeleton" />
            <Skeleton.Button className="sandbox-button-skeleton" />
            <Skeleton.Button className="sandbox-button-skeleton" />
          </Space>
        ) : (
          <Space className="w-full gp-10 mt-20" size="large">
            {environments.map((item) => (
              <Button
                key={item._id}
                type="primary"
                size='small'
                className={`sandbox-tab outline ${
                  environmentId === item._id ? 'sandbox-tab-connected' : ''
                }`}
                disabled={environmentId === item._id}
                // loading={connectingEhrId === item._id}
                onClick={() => handleEhrConnection(item)}
              >
                {item.name}
              </Button>
            ))}
          </Space>
        )}
      </div>
    );
  };

  return (
    <Modal
      className="autonomizeModal ehr-list-modal"
      centered
      width={720}
      title="Connect EHR"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>Connect with EHR</h1>
      </div>
      <div className='addClient-content'>
        {platforms.length === 0 && !loadingPlatforms ? (
          <div className="no-data-result aic jcc flex">
            <NotFitIcon className="no-data-result-icon" />
            <p>No Platform Found</p>
          </div>
        ) : (
          <Space
            className="ehr-integration-list w-full"
            direction="vertical"
            size="large"
          >
            <Table
              className='ehr-table'
              loading={loadingPlatforms}
              dataSource={dataSource}
              columns={getColumns()}
              pagination={false}
              expandable={{
                expandedRowRender,
                expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
                onExpand,
              }}
            />
          </Space>
        )}
      </div>
    </Modal>
  );
};

export default ConnectEHR;
