import { apiClient as defaultApiClient, projectService } from '.';

import axios from "axios";
import { IPatient } from '../interfaces';

const defaultOptions = {
  baseURL: process.env.REACT_APP_GENESIS_BFF_BASE_URL,
  // baseURL: window._env_.REACT_APP_GENESIS_BFF_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
};

const apiClient = axios.create(defaultOptions);

const getAllPlatforms = async () => {
  const res = await apiClient.get<IPlatform[]>('/emr/platform');
  return res;
};

const getEnvironmentsByPlatformId = async (platformId: string) => {
  const res = await apiClient.get<IEnvironment[]>(
    `/emr/platform/${platformId}/environments`,
  );
  return res;
};

const getPatients = async (environmentId: string) => {
  const res = await apiClient.get<IEhrPatient[]>(
    `/emr/${environmentId}/patients`,
  );
  return res;
};

const getPatientInsights = async (patientId: string, environmentId: string) => {
  const res = await apiClient.get<IPatient>(
    `/emr/${environmentId}/patient/${patientId}/insights`,
  );
  return res;
};

const downloadPatientPriorAuth = async (environmentId: string, patientId: string) => {
  const res = await apiClient.get(
    `/emr/${environmentId}/patient/${patientId}/prior-auth`,
    {
      responseType: 'blob',
    },
  );
  return res;
};

// const downloadPatientPdf = async (environmentId: string, patientId: string) => {
//   const res = await apiClient.get(
//     `/emr/${environmentId}/patient/${patientId}/pdf`,
//     {
//       responseType: 'blob',
//     },
//   );
//   return res;
// };

export const ehrService = {
  getEnvironmentsByPlatformId,
  getPatients,
  //   downloadPatientPdf,
  getAllPlatforms,
  getPatientInsights,
  downloadPatientPriorAuth
};
