import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

declare global {
  interface Window {
    _env_: {
      REACT_APP_API_BASE_URL: string;
      REACT_APP_RUN_IN_STRICT_MODE: string;
      REACT_APP_GENESIS_BFF_BASE_URL: string;
      [key: string]: any; // for any other properties you might have on _env_
    };
  }
}

// const runInStrictMode = window._env_.REACT_APP_RUN_IN_STRICT_MODE;
const runInStrictMode = process.env.REACT_APP_RUN_IN_STRICT_MODE;


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  runInStrictMode === 'true' ? (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ) : (
    <App />
  ),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
