import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { PatientService, PatientProjects, projectService, ehrService } from "../api";
import { IPatient } from "../interfaces";
import { setPatientInformation, setPatientName } from "../store";
import { useUser } from "./useUser";

export const useFetchPatientProjects = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {clientId} = useUser();
  const projectId = searchParams.get("projectId");
  const [selectedProject, setSelectedProject] = useState({} as PatientProjects);
  const [projects, setProjects] = useState([] as PatientProjects[]);
  const [loading, setLoading] = useState(false);
  const [patientInfo, setPatientInfo] = useState({} as IPatient);
  const dispatch = useDispatch();

  const handleProjectSelect = useCallback(
    (item: PatientProjects) => {
      navigate(
        { search: createSearchParams({ projectId: item.Id.toString() }).toString() },
        { replace: true }
      );
    },
    [navigate]
  );

  const fetchEhrPatientProjects = useCallback(async (patientId: string, envId: string) => {
    setLoading(true);
    if(projectId) {
      const {data: project} = await projectService.getOne(clientId, projectId);
      const patientProject: PatientProjects = {
        createdAt: "",
        Id: 1,
        Name: project.data.Name,
        S3Folder: ""
      }
      setProjects([patientProject]);
      const {data} = await ehrService.getPatientInsights(patientId, envId);
      dispatch(setPatientName(data.patient_name));
      setPatientInfo(data);
      dispatch(setPatientInformation({ id: data.patientId, name: data.patient_name }));
      setSelectedProject(patientProject);
    }
    setLoading(false);
  }, [dispatch, projectId])

  const fetchPatientProjects = useCallback(
    async (patientId: string) => {
      setLoading(true);
      const { data } = await PatientService.getPatientProjects(patientId);
      setProjects([...data.data]);
      if (data.data.length > 0) {
        if (projectId) {
          const res = await PatientService.getPatientInfo(patientId, projectId);
          dispatch(setPatientName(res.data.patient_name));
          setPatientInfo(res.data);
          dispatch(setPatientInformation({ id: res.data.patientId, name: res.data.patient_name }));
          const projectIndex = data.data.findIndex((item) => item.Id === Number(projectId));
          if (projectIndex !== -1) setSelectedProject(data.data[projectIndex]);
          else handleProjectSelect(data.data[0]);
        } else handleProjectSelect(data.data[0]);
      } else {
          const res = await PatientService.getPatientInfo(patientId, null);
          dispatch(setPatientName(res.data.patient_name));
          setPatientInfo(res.data);
          setSelectedProject({} as PatientProjects);
      }
      setLoading(false);
    },
    [handleProjectSelect, projectId, dispatch]
  );

  return {
    fetchPatientProjects,
    patientProjects: projects,
    selectedProject,
    patientInfo,
    patientProjectsLoading: loading,
    setSelectedProject: handleProjectSelect,
    fetchEhrPatientProjects
  };
};
