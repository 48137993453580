import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { UploadFile } from 'antd';
import { projectService } from '../../../api';
import { FileUploader } from '../../../components';
import { MODAL_TYPE, openNotificationWithIcon } from '../../../utils';
import { useDispatch } from 'react-redux';
import { openModal, setProjectId } from '../../../store';

interface Props {
  projectId: number;
  folderName: string;
  setFetchDocuments: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export const UploadProjectDocuments = (props: Props) => {
  const { projectId, setFetchDocuments, folderName, setLoading } = props;
  const dispatch = useDispatch();

  const isFileNameValid = useCallback((fileName: string) => {
    const pattern = /^\d+_.*/;
    return pattern.test(fileName);
  }, []);

  const onFileUpload = useCallback(
    async (file: UploadFile<any>) => {
      try {
        const { data } = await projectService.getS3UploadURL(
          projectId,
          file.name,
        );
        await projectService.uploadFiles(data.response, file);
      } catch (err: any) {
        openNotificationWithIcon('', err.response.data.message, 'error');
      }
    },
    [folderName, projectId],
  );

  const onFilesUploadComplete = useCallback(async () => {
    setLoading(true);
    setTimeout(() => {
      setFetchDocuments(true);
    }, 2000);
  }, [setFetchDocuments]);

  const handleConnectEhr = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      dispatch(setProjectId(projectId));
      dispatch(openModal(MODAL_TYPE.CONNECT_EHR));
    },
    [dispatch, projectId],
  );

  return (
    <FileUploader
      onSubmit={onFileUpload}
      // validateFileName={isFileNameValid}
      onFilesUploadComplete={onFilesUploadComplete}
      handleConnectEhr={handleConnectEhr}
      // validateFileNameErrorMessage={
      //   "Invalid file name format. Rename the file to start with a digit(s), followed by an underscore, and any characters"
      // }
    />
  );
};
