export enum DOCUMENT_STATUS {
  FILE_UPLOADED = 'File Uploaded',
  DATA_EXTRACTION_STARTED = 'Data Extraction Started',
  FILE_FORMATTING_AND_OCR = 'File Formatting & OCR',
  OCR_COMPLETE = 'OCR - Completed',
  CLINICAL_DATA_EXTRACTION_STARTED = 'Clinical Data Extraction Started',
  COMPLETED = 'SUCCEEDED',
  ERROR = 'ERROR',
}

export enum USER_ROLES_CODE {
  SUPER_ADMIN = '01',
  ENTERPRISE_ADMIN = '02',
  DATA_ADMIN = 'DATA SCIENTIST',
  CLINICIAN = 'CLINICIAN',
  MANAGER = 'DATA SCIENTIST',
}

export const CLINICIAN_PERMISSIONS = ['CLINICIAN'];

export enum MODAL_TYPE {
  ADD_EDIT_CLIENT = 'addEditClient',
  ADD_EDIT_USER = 'addEditUser',
  ADD_EDIT_PROJECT = 'addEditProject',
  ASSIGN_PROJECT = 'assignProject',
  DELETE_CONFIRMATION = 'deleteConfirmation',
  EDIT_PROFILE = 'editProfile',
  ADD_EDIT_USER_ROLE = 'addEditUserRole',
  ADD_USER_TO_PROJECT = 'addUserToProject',
  ADD_PATIENT_TO_COHORT = 'addPatientToCohort',
  ADD_PATIENTS_TO_PROJECT = 'addPatientsToProject',
  PROJECT_DOCUMENT_PROCESSING_DETAIL_STATUS = 'projectDocumentProcessingDetailStatus',
  USER_MULTIPLE_ROLES = 'userMultipleRoles',
  ADD_EDIT_PROJECT_CONFIGURATION = 'addEditProjectConfiguration',
  FORGOT_PASSWORD = 'forgotPassword',
  RESET_USER_PASSWORD = 'resetUserPassword',
  REMOVE_PATIENT_FROM_COHORT = 'removePatientFromCohort',
  EDIT_COHORT = 'editCohort',
  FEEDBACK = 'feedback',
  VIEW_QUES_ANS_CONTEXT = 'viewQuesAnsContext',
  LICENSE_KEY = 'licenseKey',
  ADD_EDIT_SUMMARIZATION_TASK = 'addEditSummarizationTask',
  CONNECT_EHR = 'connectEHR',
}

export enum THEME {
  LIGHT = 'light-theme',
  DARK = 'dark-theme',
}

export enum STATUS_CODE {
  FORBIDDEN = 403,
}
