import React, { useState } from "react";
import { UploadProjectDocuments } from "../project-detail-components";
import { RecentDocuments } from "../../tables";
import { Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

interface Props {
  projectId: number;
  folderName: string;
  isEhrProject: boolean;
}

export const ProjectDetailDocuments = ({ projectId, folderName, isEhrProject }: Props) => {
  const [fetchDocuments, setFetchDocuments] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleRefreshClick = () => {
    setFetchDocuments(true)
  }

  return (
    <>
      <UploadProjectDocuments
        {...{ projectId, setFetchDocuments, folderName, setLoading }}
      />
      <div className="recent-document">
        <div className="flex jcsb aic m-t m-b">
          <h2 className="basic-detail-title mb-0">Recent Documents</h2>
          <Button
            className="outline"
            title="refresh"
            onClick={handleRefreshClick}
          >
            <ReloadOutlined /> Refresh
          </Button>
        </div>
        <RecentDocuments
          {...{ projectId, fetchDocuments, setFetchDocuments, loading, setLoading, isEhrProject }}
        />
      </div>
    </>
  );
};
