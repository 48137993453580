import { useCallback, useState } from 'react';
import { useAuthorization } from './useAuthorization';
import { STATUS_CODE, openNotificationWithIcon } from '../utils';
import { ehrService } from '../api';

export const useConnectEhr = () => {
  const [loadingPlatforms, setLoadingPlatforms] = useState(false);
  const [loadingEnvironments, setLoadingEnvironments] = useState(false);
  const [environments, setEnvionments] = useState([] as IEnvironment[]);
  const [platforms, setPlatforms] = useState([] as IPlatform[]);
  const { setIsAuthorized } = useAuthorization();

  const fetchEnvironments = useCallback(
    async (platformId: string) => {
      setLoadingEnvironments(true);
      try {
        const { data } = await ehrService.getEnvironmentsByPlatformId(
          platformId,
        );
        setEnvionments([...data]);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon('', err.response.data.message, 'error');
        }
      } finally {
        setLoadingEnvironments(false);
      }
    },
    [setIsAuthorized],
  );

  const fetchPlatforms = useCallback(async () => {
    setLoadingPlatforms(true);
    try {
      const { data } = await ehrService.getAllPlatforms();
      setPlatforms([...data]);
    } catch (err: any) {
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon('', err.response.data.message, 'error');
      }
    } finally {
      setLoadingPlatforms(false);
    }
  }, [setIsAuthorized]);

  return {
    fetchPlatforms,
    loadingPlatforms,
    platforms,
    fetchEnvironments,
    loadingEnvironments,
    environments,
  };
};
