import { combineReducers } from 'redux';

import uiReducer, { uiInitialState } from './ui/slice';
import searchReducer, { searchInitialState } from './search/slice';
import userReducer, { userInitialState } from './user/slice';
import dataReducer, { dataInitialState } from './data/slice';
import patientReducer, {
  patientSliceInitialState,
} from './patients-pagination/slice';
import projectReducer, { projectInitialState } from './project/slice';
import annotationReducer, {
  annotationInitialState,
} from './annotation-tool/slice';
import cohortReducer, { cohortInitialState } from './cohort/slice';
import feedbacKReducer, { feedbackInitialState } from './feedback/slice';
import QuesAnsReducer, { quesAnsInitialState } from './question-answer/slice';
import connectEhrReducer, { connectEhrInitialState } from './connect-ehr/slice';

export const initialState = {
  ui: uiInitialState,
  search: searchInitialState,
  user: userInitialState,
  data: dataInitialState,
  patient: patientSliceInitialState,
  project: projectInitialState,
  annotation: annotationInitialState,
  cohort: cohortInitialState,
  feedback: feedbackInitialState,
  quesAns: quesAnsInitialState,
  connectEhr: connectEhrInitialState,
};

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    ui: uiReducer,
    search: searchReducer,
    user: userReducer,
    data: dataReducer,
    patient: patientReducer,
    project: projectReducer,
    annotation: annotationReducer,
    cohort: cohortReducer,
    feedback: feedbacKReducer,
    quesAns: QuesAnsReducer,
    connectEhr: connectEhrReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
