import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Dropdown, Loading } from "../../../components";
import { PatientProjects, projectService } from "../../../api";
import { PatientDetailCardProps } from "..";
import { DropdownOption, IPatient } from "../../../interfaces";
import { useFetchPatientStatusOptions } from "../../../hooks";
import {
  getLocalDateTimeStringFromISO,
  openNotificationWithIcon,
} from "../../../utils";

interface Props {
  patientInfo: IPatient;
  selectedProject: PatientProjects;
  patientProjects: PatientProjects[];
  setReviewStatusMap: Dispatch<SetStateAction<PatientDetailCardProps[]>>;
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
  isEhrPatient: boolean;
}

export const PatientInsightStatus = (props: Props) => {
  const { patientInfo, selectedProject, setReviewStatusMap, isEhrPatient } = props;
  const { projectStatusInfo: patientStatusInfo } = patientInfo;
  const [reviewStatus, setReviewStatus] = useState(
    patientStatusInfo?.projectStatusName
  );
  const projectId = selectedProject.Id ? selectedProject.Id : -1;
  const {
    fetchPatientStatusList,
    setPatientStatusLoading,
    patientStatusLoading,
    patientStatusOptions,
  } = useFetchPatientStatusOptions({ setIsAuthorized: props.setIsAuthorized });

  const handleStatusChange = async (value: string, option: DropdownOption) => {
    try {
      setPatientStatusLoading(true);
      const { data } = await projectService.updatePatientStatus(
        option.id,
        patientStatusInfo?.patientProjectId
      );
      setReviewStatusMap([
        { heading: "Reviewed By", value: data.data.updatedBy },
        {
          heading: "Reviewed On",
          value: getLocalDateTimeStringFromISO(data.data.updatedAt),
        },
      ]);
      setReviewStatus(option.value);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setPatientStatusLoading(false);
    }
  };

  useEffect(() => {
    const getPatientStatusOptions = async () => {
      if (projectId !== -1) {
        await fetchPatientStatusList();
      }
    };
    getPatientStatusOptions();
  }, [fetchPatientStatusList, projectId]);

  useEffect(() => {
    if(isEhrPatient) setReviewStatus('Not reviewed')
    else setReviewStatus(patientStatusInfo?.projectStatusName);
  }, [patientStatusInfo?.projectStatusName]);

  return (
    <div>
      {projectId !== -1 && (
        <div className="ai-select-box">
          <Dropdown
            value={reviewStatus}
            options={patientStatusOptions}
            loading={patientStatusLoading}
            onSelect={isEhrPatient ? () => {} : handleStatusChange}
            notFoundContent={patientStatusLoading && <Loading />}
          />
        </div>
      )}
    </div>
  );
};
