import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import ConnectEHR from './ConnectEHR';
import {
  closeModal,
  RootState,
  setProjectEhrEnvironment,
} from '../../../store';
import { MODAL_TYPE } from '../../../utils';

type StateProps = {
  visibility: boolean;
  projectId: number;
  environmentId: string | null;
};
type DispatchProps = {
  handleClose: () => void;
  connectProjectToEhrEnv: (projectId: number, envId: string) => void;
};
export type ConnectEhrProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => {
  const projectId = state.ui.projectId;
  let envId = null;
  if (projectId in state.connectEhr.projectEhrEnvMap) {
    envId = state.connectEhr.projectEhrEnvMap[projectId];
  }
  return {
    projectId: state.ui.projectId,
    environmentId: envId,
    visibility: state.ui.modal[MODAL_TYPE.CONNECT_EHR],
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.CONNECT_EHR));
  },
  connectProjectToEhrEnv: (projectId: number, envId: string) => {
    dispatch(setProjectEhrEnvironment({ projectId, environmentId: envId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectEHR);
