import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  // useMemo,
  useState,
} from "react";
import {
  IHEDISMeasureInsight,
  IPatient,
  ProjectSpecific,
  ProjectSpecificTiles,
  // ProjectSpecificTiles,
} from "../../interfaces";
import {
  AskAQuestion,
  ClinicalInsights,
  HedisCareGaps,
  HedisMeasureInsights,
  PatientInsightActionItems,
  // PatientInsightTiles,
  PatientInsightsTabs,
  ProjectInsights,
  ProjectSpecificInsightTiles,
  // ProjectSpecificInsightTiles,
  Summary,
} from "./patient-insight-container";
import { PATIENT_INSIGHT_TABS_NEW, PatientDetailCardProps } from ".";
import { PatientProjects } from "../../api";
import "./patient-insight-container.scss";
// import { PatientInsightHeader } from "./patient-insight-container/pateint-insight-header/PatientInsightHeader";
import { PatientPagination } from "./patient-insight-container/pateint-insight-header";
import { appendTabToRouteQuery } from "../../utils";
import { useSearchParams } from "react-router-dom";
import { summaryService } from "../../api/summarization";

interface Props {
  patientInfo: IPatient;
  selectedProject: PatientProjects;
  patientProjects: PatientProjects[];
  hedisMeasures: IHEDISMeasureInsight;
  setReviewStatusMap: Dispatch<SetStateAction<PatientDetailCardProps[]>>;
  setSelectedProject: (item: PatientProjects) => void;
  reviewStatusMap: PatientDetailCardProps[];
  patientProjectsLoading: boolean;
  isHedisMeasureLoading: boolean;
  isHedisViewAuthorized: boolean;
  setIsHedisViewAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
  isEhrPatient: boolean;
}

export const PatientInsightContainer = (props: Props) => {
  const {
    patientInfo,
    patientProjects,
    selectedProject,
    // setSelectedProject,
    // reviewStatusMap,
    // patientProjectsLoading,
    setReviewStatusMap,
    hedisMeasures,
    isHedisMeasureLoading,
    isHedisViewAuthorized,
    setIsHedisViewAuthorized,
    isEhrPatient
  } = props;

  const projectSpecific = useMemo(() => {
    return patientInfo.project_specific
      ? patientInfo.project_specific
      : ([] as ProjectSpecific[]);
  }, [patientInfo.project_specific]);

  const [selectedTab, setSelectedTab] = useState(
    PATIENT_INSIGHT_TABS_NEW.SUMMARY
  );
  const [component, setComponent] = useState(<></>);
  const [searchParams, setSearchParams] = useSearchParams();
  const [projectSpecificSummary, setProjectSpecificSummary] = useState() as any;
  const [summary, setSummary] = useState() as any;

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    setSelectedTab(tab || PATIENT_INSIGHT_TABS_NEW.SUMMARY);
  }, []);

  useEffect(() => {
    const getProjectSpecificSummary = async () => {
      try {
        const res = await summaryService.getProjectSpecificSummary(
          patientInfo.fileList[0]?.UUID
        );
        console.log(res.data.data)
        setProjectSpecificSummary(res.data.data);
      } catch (err: any) {
        setProjectSpecificSummary(err.response.data.message);
      }
    };
    if(!isEhrPatient) getProjectSpecificSummary();
  }, []);

  useEffect(() => {
    const getPatientSummary = async () => {
      const res = await summaryService.getPatientSummary(patientInfo.fileList[0]?.UUID);
      setSummary(res.data.data[0].summary);
    };
    if(!isEhrPatient) getPatientSummary();
  }, []);

  useEffect(() => {
    let res = <></>;
    switch (selectedTab) {
      case PATIENT_INSIGHT_TABS_NEW.SUMMARY:
        res = <Summary patientInfo={patientInfo} summary={summary} />;
        break;
      case PATIENT_INSIGHT_TABS_NEW.CLINICAL_INSIGHTS:
        res = <ClinicalInsights {...{ patientProjects, patientInfo, isEhrPatient }} />;
        break;
      // case PATIENT_INSIGHT_TABS_NEW.HEDIS_CARE_GAPS:
      //   res = <HedisCareGaps patientInfo={patientInfo} />;
      //   break;
      case PATIENT_INSIGHT_TABS_NEW.PROJECT_SPECIFIC_INSIGHTS:
        res = (
          <ProjectInsights
            projectSpecific={projectSpecific}
            projectSpecificSummary={projectSpecificSummary}
            patientInfo={patientInfo}
          />
        );
        break;
      // case PATIENT_INSIGHT_TABS_NEW.HEDIS_MEASURE_INISGHTS:
      //   res = (
      //     <HedisMeasureInsights
      //       {...{
      //         patientInfo,
      //         hedisMeasures,
      //         isHedisMeasureLoading,
      //         isHedisViewAuthorized,
      //         setIsHedisViewAuthorized,
      //       }}
      //     />
      //   );
        break;
      case PATIENT_INSIGHT_TABS_NEW.ASK_A_QUESTION:
        res = <AskAQuestion {...{ patientInfo }} />;
        break;
    }
    setComponent(res);
    appendTabToRouteQuery(selectedTab);
  }, [
    selectedTab,
    patientInfo,
    patientProjects,
    hedisMeasures,
    isHedisMeasureLoading,
    isHedisViewAuthorized,
    setIsHedisViewAuthorized,
    summary,
    projectSpecificSummary
  ]);

  return (
    <>
      <div className="flex jcsb aic m-b">
        <PatientInsightActionItems
          {...{
            selectedProject,
            patientProjects,
            patientInfo,
            setReviewStatusMap,
          }}
        />
        <PatientPagination patientId={patientInfo.patientId} />
      </div>
      <PatientInsightsTabs {...{ onChange, selectedTab }} />
      {component}
      {/* <PatientInsightHeader
        {...{
          patientInfo,
          patientProjects,
          selectedProject,
          setSelectedProject,
          reviewStatusMap,
          patientProjectsLoading,
          setReviewStatusMap,
        }}
      /> */}
      {/* <PatientInsightTiles patientInfo={patientInfo} />
      {patientProjects.length > 0 && (
        <ProjectSpecificInsightTiles
          projectSpecific={projectSpecific}
          patientId={patientInfo.patientId}
          threshold_confidence_score={patientInfo.threshold_confidence_score}
          biomarkers={patientInfo.biomarkers}
          riskFactors={patientInfo.risk_factor}
        />
      )} */}
    </>
  );
};
