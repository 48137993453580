import React, { Dispatch, SetStateAction, useState } from 'react';
import { ehrService, PatientProjects } from '../../../api';
import { PatientDetailCardProps } from '..';
import { IPatient } from '../../../interfaces';
import { useDispatch } from 'react-redux';
import { useUser } from '../../../hooks';
import { openModal, updateAssignPatientsList } from '../../../store';
import { CLINICIAN_PERMISSIONS, MODAL_TYPE, openNotificationWithIcon } from '../../../utils';
import PatientProfileProjects from './PatientProfileProjects';
import { Button } from 'antd';
import { PatientProfileInfo } from './PatientProfileInfo';
import { FileListCard } from './FileListCard';
import { PatientInsightStatus } from './PatientInsightStatus';

interface Props {
  patientInfo: IPatient;
  patientProjects: PatientProjects[];
  selectedProject: PatientProjects;
  setSelectedProject: (item: PatientProjects) => void;
  reviewStatusMap: PatientDetailCardProps[];
  patientProjectsLoading: boolean;
  setReviewStatusMap: Dispatch<SetStateAction<PatientDetailCardProps[]>>;
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
  isEhrPatient: boolean;
  environmentId: string;
}

export const PatientInsightHeader = (props: Props) => {
  const {
    patientInfo,
    patientProjects,
    selectedProject,
    setSelectedProject,
    reviewStatusMap,
    patientProjectsLoading,
    setReviewStatusMap,
    isAuthorized,
    setIsAuthorized,
    isEhrPatient,
    environmentId
  } = props;
  const { patientId, mrn, source, fileList } = patientInfo;
  const dispatch = useDispatch();
  const { roleCode } = useUser();
  const [downloadingPriorAuth, setDownloadingPriorAuth] = useState(false);

  const onAssignProject = () => {
    if(!isEhrPatient) {
      dispatch(
        updateAssignPatientsList({
          patientIds: [patientId],
          alreadyAssignedProjects: patientProjects.map((item) => item.Id),
        }),
      );
      dispatch(openModal(MODAL_TYPE.ADD_PATIENTS_TO_PROJECT));
    }
  };

  const onAssignCohort = () => {
    if(!isEhrPatient) {
      dispatch(openModal(MODAL_TYPE.ADD_PATIENT_TO_COHORT));
    }
  };

  const downloadPriorAuth = async () => {
    setDownloadingPriorAuth(true);
    try {
      const response = await ehrService.downloadPatientPriorAuth(environmentId, patientId.toString());
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `patient_${patientId}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      openNotificationWithIcon("", "Failed to download PDF:", "error");
    } finally {
      setDownloadingPriorAuth(false);
    }
  }

  return (
    <div>
      <PatientProfileProjects
        {...{
          projects: patientProjects,
          selectedProject,
          setSelectedProject,
          patientProjectsLoading,
        }}
      />
      <div className="flex gp-10 m-b">
        {!CLINICIAN_PERMISSIONS.includes(roleCode) && (
          <>
            <Button type="primary" className="fill" onClick={onAssignProject}>
              Assign to Project
            </Button>
          </>
        )}
        <Button type="primary" className="fill" onClick={onAssignCohort}>
          Assign to Cohort
        </Button>
      </div>
      <PatientProfileInfo
        patientInfo={{
          mrn,
          source: source,
        }}
        reviewStatusMap={reviewStatusMap}
      />
      <div className="flex aic gp m-t">
        <h4 className="mb-0">Status: </h4>
        <PatientInsightStatus
          {...{
            selectedProject,
            patientInfo,
            patientProjects,
            setReviewStatusMap,
            isAuthorized,
            setIsAuthorized,
            isEhrPatient
          }}
        />
      </div>
      {fileList && <FileListCard fileList={fileList} isClickDisabled={isEhrPatient}/>}
      {isEhrPatient && <div className="m-t">
        <Button type="primary" className="fill" onClick={downloadPriorAuth} loading={downloadingPriorAuth}>
          Generate Patient Pharmacy Prior Auth
        </Button>
      </div>}
    </div>
  );
};
